<template>
    <div>
      <sidebar />
      <div class="relative md:ml-64 bg-blueGray-100" style="overflow-x:visible">
        <admin-navbar />
        <Header />
      </div>
      <div class="relative md:ml-64" style="overflow-x:visible">
        <div class="px-4 md:px-10 mx-auto w-full -m-24 main-boss">
          <router-view />
          <footer-admin />
        </div>
      </div>
    </div>
  </template>
  <script>
  import AdminNavbar from "@/components/Navbars/AdminNavbar.vue";
  import Sidebar from "@/components/Sidebar/Sidebar.vue";
  import Header from "@/components/Headers/Header.vue";
  import FooterAdmin from "@/components/Footers/FooterAdmin.vue";
  export default {
    name: "admin-layout",
    components: {
      AdminNavbar,
      Sidebar,
      Header,
      FooterAdmin,
    },
  };
  </script>
