<template>
  <form id="form_config_fee" @submit.prevent="submitForm($event)">
    <div class="flex flex-wrap" style="grid-row-gap: 10px;">
      <div class="w-full lg:w-3/12 px-4">
        <div class="relative w-full mb-3">
          <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          >
            Đối tượng trả Phí <small class="dot-red-label"></small>
          </label>
          <select :disabled="disableProperty" v-model="config.object_type" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
            <option value="" disabled selected>Chon đối tượng</option>
            <option value="1">Merchant</option>
          </select>
        </div>
      </div>
      <div class="w-full lg:w-3/12 px-4">
        <div class="relative w-full mb-3">
          <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          >
            Chọn loại phí <small class="dot-red-label"></small>
          </label>
          <select :disabled="disableProperty"  v-model="config.type" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
            <option value="" disabled selected>Chon loại phí</option>
            <option value="1">Phí thu</option>
          </select>
        </div>
      </div>
      <div class="w-full lg:w-3/12 px-4">
        <div class="relative w-full mb-3">
          <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          >
            Chọn Merchant <small class="dot-red-label"></small>
          </label>
          <multiselect
              v-model="config.object_id"
              :options="merchantOptions"
              placeholder="Vui lòng nhập..."
              :searchable="true"
              :disabled="disableProperty"
              :loading="merchantLoading"
              :options-limit="50"
              open-direction="bottom"
              track-by="name"
              label="name"
              @search-change="searchMerchant"
          ></multiselect>
        </div>
      </div>
      <div class="w-full lg:w-3/12 px-4">
        <div class="relative w-full mb-3">
          <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          >
            Gói dịch vụ
          </label>
          <select :disabled="disableProperty" v-model="config.service_id" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
            <option value="" disabled selected>Chọn gói dịch vụ</option>
          </select>
        </div>
      </div>
      <div class="w-full lg:w-3/12 px-4">
        <div class="relative w-full mb-3">
          <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          >
            Tính phí cho <small class="dot-red-label"></small>
          </label>
          <select :disabled="disableProperty" v-model="config.charge_to" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
            <option value="" disabled selected>Lựa chọn</option>
            <option value="1">Merchant</option>
            <option value="2">User</option>
          </select>
        </div>
      </div>
      <div class="w-full lg:w-3/12 px-4">
        <div class="relative w-full mb-3">
          <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          >
            Phương thức tính phí <small class="dot-red-label"></small>
          </label>
          <select :disabled="disableProperty" v-model="config.method" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
            <option value="" disabled selected>Lựa chọn</option>
            <option value="1">Phí phẳng</option>
          </select>
        </div>
      </div>
      <div class="w-full lg:w-3/12 px-4">
        <div class="relative w-full mb-3">
          <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          >
            Chi phí tích hợp (VND)
          </label>
          <input
              :disabled="disableProperty"
              type="text"
              v-model="config.integration"
              @input="($event) => {config.integration = replaceToCurrency($event)}"
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
          />
        </div>
      </div>
    </div>

    <!-- Block Service Fee -->
    <div class="config_service_block">
      <div v-if="config.blocks.length === 0" class="max-w-sm w-full lg:max-w-full lg:flex mb-3 mt-3 config_service_block_item border rounded-md px-2 py-5 items-center">
        <i>Không có config nào!</i>
      </div>
      <div v-bind:key="config_block_key" v-for="(service_item, config_block_key) in config.blocks" class="max-w-sm w-full lg:max-w-full lg:flex mb-3 mt-3 config_service_block_item border rounded-md px-2 py-5 items-center">
        <div class="w-full lg:w-4/12 px-4">
          <div class="relative w-full mb-3">
            <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
            >
              Loại dịch vụ thu phí <small class="dot-red-label"></small>
            </label>
            <multiselect
                v-model="service_item.service_types"
                tag-placeholder="Vui lòng lựa chọn"
                placeholder="Thanh toán QR, nạp tiền, rút..."
                label="label"
                track-by="id"
                :disabled="disableProperty"
                :options="service_types"
                :multiple="true"
                :taggable="true"
            ></multiselect>
          </div>
        </div>
        <div class="w-full lg:w-3/12 px-4">
          <div class="relative w-full mb-3">
            <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
            >
              Tính phí theo giá trị <small class="dot-red-label"></small>
            </label>
            <select :disabled="disableProperty" v-model="service_item.charge_type" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
              <option value="1">Giá trị giao dịch</option>
            </select>
          </div>
        </div>
        <div class="w-full lg:w-3/12 px-4">
          <div class="relative w-full mb-3">
            <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
            >
              Phí xử lý
            </label>
            <div class="relative">
              <input
                  :disabled="disableProperty"
                  type="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="0.00"
                  v-model="service_item.processing_cost"
              />
              <div class="absolute inset-y-0 right-0 flex items-center" style="top: 2px;">
                <label for="currency" class="sr-only">Currency</label>
                <select :disabled="disableProperty" v-model="service_item.processing_cost_unit" id="currency" name="currency" class="h-full rounded-md border-0 bg-transparent py-0 pl-2 pr-7 text-gray-500 sm:text-sm" style="box-shadow: none;">
                  <option value="1">%</option>
                  <option value="2">VND</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="w-full lg:w-3/12 px-4">
          <div class="relative w-full mb-3">
            <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
            >
              Giá trị phí
            </label>
            <div class="relative">
              <input
                  :disabled="disableProperty"
                  type="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="0.00"
                  v-model="service_item.cost_value"
              />
              <div class="absolute inset-y-0 right-0 flex items-center" style="top: 2px;">
                <label for="currency" class="sr-only">Currency</label>
                <select :disabled="disableProperty" v-model="service_item.cost_value_unit" id="currency" name="currency" class="h-full rounded-md border-0 bg-transparent py-0 pl-2 pr-7 text-gray-500 sm:text-sm" style="box-shadow: none;">
                  <option value="1">%</option>
                  <option value="2">VND</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="w-full lg:w-3/12 px-4">
          <div class="relative w-full mb-3">
            <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
            >
              Cộng thêm (VND)
            </label>
            <input
                :disabled="disableProperty"
                type="text"
                v-model="service_item.additional"
                @input="($event) => {service_item.additional = replaceToCurrency($event)}"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
            />
          </div>
        </div>
        <div class="w-full lg:w-3/12 px-4">
          <div class="relative w-full mb-3">
            <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
            >
              Giá trị tối thiểu (VND)
            </label>
            <input
                :disabled="disableProperty"
                type="text"
                v-model="service_item.min_value"
                @input="($event) => {service_item.min_value = replaceToCurrency($event)}"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
            />
          </div>
        </div>
        <div class="w-10" v-if="!disableProperty">
          <i class="cursor-pointer text-red-500" title="Xoá bỏ cấu hình này" @click="removeServiceConfig(config_block_key)">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
            </svg>
          </i>
        </div>

      </div>
      <button v-if="!disableProperty" type="button" @click="addNewServiceConfig()" class="py-1 px-4 bg-emerald-500 hover:bg-emerald-600 text-white font-semibold rounded-lg shadow-md focus:outline-none" style="
          font-size: 13px;
          font-weight: 500;
      ">Thêm config dịch vụ</button>
    </div>

    <div class="mt-5">
      <div class="flex flex-wrap mt-5" style="margin-left: 1rem">
        <div class="w-full ">
          <div class="relative flex gap-x-3">
            <div class="flex h-6 items-center">
              <input id="comments" :disabled="disableProperty" v-model="config.has_based_user" name="has_based_user" type="checkbox" checked class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 cursor-pointer">
            </div>
            <div class="text-sm leading-6 ml-3">
              <label for="comments" class="font-medium text-gray-900 cursor-pointer">Tính phí theo user</label>
            </div>
          </div>
        </div>
      </div>
      <div v-show="config.has_based_user" class="flex flex-wrap mt-5">
        <div class="w-full lg:w-3/12 px-4">
          <div class="relative w-full mb-3">
            <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
            >
              Loại User <small class="dot-red-label"></small>
            </label>
            <select :disabled="disableProperty" v-model="config.based_user_type" name="based_user_type" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
              <option value="" disabled selected>Lựa chọn</option>
              <option value="2">User phát sinh giao dịch</option>
            </select>
          </div>
        </div>
        <div class="w-full lg:w-3/12 px-4">
          <div class="relative w-full mb-3">
            <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
            >
              Giá trị phải trả (user/VND) <small class="dot-red-label"></small>
            </label>
            <input
                :disabled="disableProperty"
                type="text"
                v-model="config.fee_based_user"
                @input="($event) => {config.fee_based_user = replaceToCurrency($event)}"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
            />
          </div>
        </div>
      </div>
    </div>

    <hr class="mt-6 border-b-1 border-blueGray-300" />
    <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
      Thời gian hiệu lực
    </h6>
    <div class="flex flex-wrap mt-5">
      <div class="w-full lg:w-3/12 px-4">
        <div class="relative w-full mb-3">
          <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          >
            Hiệu lục từ <small class="dot-red-label"></small>
          </label>
          <input
              :disabled="disableProperty"
              type="date"
              lang="vi"
              v-model="config.effective_from"
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
          />
        </div>
      </div>
      <div class="w-full lg:w-3/12 px-4">
        <div class="relative w-full mb-3">
          <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          >
            Hiệu lực đến <small class="dot-red-label"></small>
          </label>
          <input
              type="date"
              lang="vi"
              v-model="config.effective_to"
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
          />
        </div>
      </div>
      <div class="w-full px-4 flex mt-3" style="grid-column-gap: 50px;">
        <div class="flex items-center gap-x-3">
          <input v-model="config.status" id="rd_status_active" name="push-notifications" value="1" type="radio" class="cursor-pointer h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600">
          <label for="rd_status_active" class="cursor-pointer ml-2 block text-sm font-medium leading-6 text-gray-900">Kích hoạt</label>
        </div>
        <div class="flex items-center gap-x-3">
          <input v-model="config.status" id="rd_status_not_active" name="push-notifications" value="2" type="radio" class="cursor-pointer h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600">
          <label for="rd_status_not_active" class="cursor-pointer ml-2 block text-sm font-medium leading-6 text-gray-900">Không kích hoạt</label>
        </div>
      </div>
    </div>
  </form>
</template>
<style>
  .config_service_block {
    margin: 0 15px;
  }
</style>
<script>
import Multiselect from 'vue-multiselect'
import {FormatCurrency} from '@/utils/numberUtils';
import {ConvertToInt} from '@/utils/stringUtils';
import 'vue-multiselect/dist/vue-multiselect.css';
import apiClient from "@/services/api";
import {debounce} from 'vue-debounce'
import moment from "moment";
import ServiceTypes from "@/config/fees/service-type";
import { toast } from 'vue3-toastify';

const debounceFn = debounce(callback => callback(), '400ms')

export default {
    data() {
      return {
        merchantLoading: false,
        merchantOptions: [],
        service_types: ServiceTypes,
        disableProperty: false,
        config: {
          type: 1,
          object_type: 1,
          object_id: null,
          service_id: "",
          charge_to: 1,
          method: 1,
          blocks: [
            {
              service_types: [],
              charge_type: 1,
              processing_cost: 0,
              processing_cost_unit: 1,
              cost_value: 0,
              cost_value_unit: 1,
              additional: 0,
              min_value: 0,
            }
          ],
          integration: 0,
          has_based_user: true,
          based_user_type: 2,
          fee_based_user: 0,
          effective_from: moment().add(1, 'days').format('YYYY-MM-DD'),
          effective_to: moment().add(1, 'years').format('YYYY-MM-DD'),
          status: 1,
          status_config : 1
        },
        root: {
          effective_to: null,
          status: null,
        },
        isChangeValue: false,
      };
    },
    components: {
      Multiselect,
    },
    props: {
      config_data: {
        type: Number
      }
    },
    created() {
      this.searchMerchant();
      if (!this.config_data) {
        console.log('this.config_data', this.config_data);

        return;
      }
      console.log('this.config_data', this.config_data);
      this.config.type = this.config_data.type;
      this.config.object_type = this.config_data.object_type;
      this.config.object_id = this.config_data.object_data;
      this.config.service_id = this.config_data.service_id || '';
      this.config.charge_to = this.config_data.charge_to;
      this.config.method = this.config_data.method;
      this.config.blocks = this.config_data.blocks.map(item => {
        let newItem = {...item};
        newItem.service_types = item.service_types.map(type => {
          return this.service_types.find(enumType => enumType.id === type);
        })
        newItem.additional = FormatCurrency(item.additional);
        newItem.min_value = FormatCurrency(item.min_value);
        return newItem;
      });
      this.config.integration = FormatCurrency(this.config_data.integration);
      this.config.has_based_user = this.config_data.has_based_user;
      this.config.based_user_type = this.config_data.based_user_type;
      this.config.fee_based_user = FormatCurrency(this.config_data.fee_based_user);
      this.config.effective_from = this.config_data.effective_from;
      this.config.effective_to = this.config_data.effective_to;
      this.config.status = this.config_data.status;
      this.disableProperty = this.config_data.is_running;
      this.root.effective_to = this.config.effective_to;
      this.root.status = this.config.status;
      this.config.status_config = this.config_data.status_config
    },
    watch: {
      "config.effective_to": function(newValue) {
        if (this.disableProperty == false) {
          return;
        }

        this.isChangeValue = newValue != this.root.effective_to;
      },
      "config.status": function(newValue) {
        if (this.disableProperty == false) {
          return;
        }

        this.isChangeValue = newValue != this.root.status;
      },
      isChangeValue() {
        toast("Config sẽ có hiệu lực sau 30 phút nếu thay đổi!", {
          "type": "warning",
          "transition": "flip",
          "dangerouslyHTMLString": true
        })
      }
    },
    methods: {
      searchMerchant(query) {
        debounceFn(async () => {
          this.merchantLoading = true;
          const self = this;
          try {
            let { data } = await apiClient.get('api/merchant/search', {
              params: {keyword: query}
            })
            self.merchantLoading = false;
            if (data.status) {
              self.merchantOptions = data.data;
            }
          } catch (e) {
            console.log(e);
          }
        })
      },
      addNewServiceConfig() {
        this.config.blocks.push({
          service_types: [],
          charge_type: 1,
          processing_cost: 0,
          processing_cost_unit: 1,
          cost_value: 0,
          cost_value_unit: 1,
          additional: 0,
          min_value: 0,
        })
      },
      removeServiceConfig(index) {
        this.config.blocks = this.config.blocks.filter((item, key) => key !== index);
      },
      replaceToCurrency(event) {
        let string = event.target?.value || '';
        return FormatCurrency(Number(string.replace(/\./g, '')))
      },
      submitForm(event) {
        event.preventDefault();
        this.$emit('submit-form', {
          ...this.config,
          charge_to: Number(this.config.charge_to),
          integration: ConvertToInt(this.config.integration),
          fee_based_user: ConvertToInt(this.config.fee_based_user),
          min_value: ConvertToInt(this.config.min_value),
          object_id: this.config.object_id?.id,
          blocks: this.config.blocks.map((item) => {
            return {
              ...item,
              service_types: item.service_types.map(item => item.id),
              processing_cost_unit: Number(item.processing_cost_unit),
              cost_value_unit: Number(item.processing_cost_unit),
              additional: ConvertToInt(item.additional),
              min_value: ConvertToInt(item.min_value),
            };
          })
        })
      }
    },
  };
</script>
