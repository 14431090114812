<template>
    <!-- Header -->
    <div class="relative bg-emerald-600 md:pt-32 pb-32 pt-12" id="main-content">
      <div class="px-4 md:px-10 mx-auto w-full" >

      </div>
    </div>
  </template>

  <script>
//   import CardStats from "@/components/Cards/CardStats.vue";

  export default {
    components: {
    //   CardStats,
    },
  };
  </script>
