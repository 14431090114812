// router.js
import { createWebHistory, createRouter } from "vue-router";
import Admin from "@/layouts/Admin.vue";
import Auth from "@/layouts/Auth.vue";
import ManagerMerchant from "@/layouts/ManagerMerchant.vue";
import Dashboard from "@/views/admin/Dashboard.vue";
import Settings from "@/views/admin/Settings.vue";
import Tables from "@/views/admin/Tables.vue";
import merchantManager from "@/views/merchantManager/Index.vue";
import formMerchant from "@/views/merchantManager/formMerchant.vue";
import Login from "@/views/auth/Login.vue";
import Register from "@/views/auth/Register.vue";
import NotFound from "@/components/page404.vue";
import authMiddleware from './middleware/auth';
import account from "@/views/account/Index.vue";
import role from "@/views/role/Index.vue";
import Action from "@/views/role/Action.vue";
import permisison from "@/views/permission/Index.vue";
import PermissionUser from "@/views/account/Permission.vue";
import formAdmin from "@/views/account/formAdmin.vue";
import BaseLayout from "@/layouts/BaseLayout.vue";
import reportMerchant from "@/views/report/merchant.vue";
import CreateFee from "@/views/fees/Create.vue";
import UpdateFee from "@/views/fees/Update.vue";
import ListFee from "@/views/fees/List.vue";
import Reconcile from "@/views/fees/Reconcile.vue";
import ListVas from "@/views/tool/config-vas/index.vue"
import StoreConfigVas from "@/views/tool/config-vas/form.vue"
import EditConfigVas from "@/views/tool/config-vas/edit.vue"
import ListToolMerchantQR from "@/views/tools/merchant-qr/List.vue";
import CreateMerchantQR from "@/views/tools/merchant-qr/Create.vue";
import UpdateMerchantQR from "@/views/tools/merchant-qr/Update.vue";
import ListTransactionVA from '@/views/va-transaction/List.vue'
import DetailTransaction from '@/views/va-transaction/Detail.vue'

const routes = [
  {
    path: "/",
    redirect: "/admin/dashboard",
    component: Admin,
    meta: {
      middleware: authMiddleware, // Truyền middleware vào meta
      loggedIn: false, // Giá trị của biến loggedIn
    },
    children: [
      {
        path: "/admin/dashboard",
        name: "dashboard",
        component: Dashboard,
      },
      {
        path: "/admin/settings",
        component: Settings,
      },
      {
        path: "/admin/tables",
        component: Tables,
      },
      // {
      //   path: "/admin/account",
      //   component: account,
      // },
    ],
  },
  {
    path: "/admin/merchantManager",
    redirect: "/admin/merchantManager/index",
    component: ManagerMerchant,
    meta: {
      middleware: authMiddleware, // Truyền middleware vào meta
    },
    children: [
      {
        path: "/admin/merchantManager/index",
        component: merchantManager,
        meta: { requiresAuth: true },
      },
      {
        path: "/admin/merchantManager/add",
        component: formMerchant,
        meta: { requiresAuth: true },
      },
      {
        path: "/admin/merchantManager/edit/:id",
        component: Register,
        meta: { requiresAuth: true },
      },
    ],
  }, {
    path: "/admin/tool",
    redirect: "/admin/tool",
    component: BaseLayout,
    meta: {
      middleware: authMiddleware,
    },
    children: [
      {
        path: "/admin/tool/merchant-qr",
        component: ListToolMerchantQR,
        name: 'admin-tool-merchant-qr',
        meta: { requiresAuth: true },
      }, {
        path: "/admin/tool/merchant-qr/create",
        component: CreateMerchantQR,
        meta: { requiresAuth: true },
      }, {
        path: "/admin/tool/merchant-qr/:id",
        component: UpdateMerchantQR,
        meta: { requiresAuth: true },
      },
      {
          path: "/admin/tool/config-vas",
          component: ListVas,
          meta: { requiresAuth: true },
      },
      {
          path: "/admin/tool/config-vas/create",
          component: StoreConfigVas,
          meta: { requiresAuth: true },
      },
      {
          path: "/admin/tool/config-vas/edit/:id",
          component: EditConfigVas,
          meta: { requiresAuth: true, edit: true },
      },
      {
          path: "/admin/tool/config-vas/:id",
          component: EditConfigVas,
          meta: { requiresAuth: true, detail: true },
      },
    ],
  }, {
    path: "/admin/config-fee",
    redirect: "/admin/config-fee/index",
    component: BaseLayout,
    meta: {
      middleware: authMiddleware,
    },
    children: [
      {
        path: "/admin/config-fee/index",
        component: ListFee,
        name: 'admin-config-fee',
        meta: { requiresAuth: true },
      }, {
        path: "/admin/config-fee/create",
        component: CreateFee,
        meta: { requiresAuth: true },
      }, {
        path: "/admin/config-fee/:id",
        component: UpdateFee,
        meta: { requiresAuth: true },
      }, {
        path: "/admin/config-fee/reconcile",
        component: Reconcile,
        meta: { requiresAuth: true },
      },
    ],
  }, {
    path: "/admin/va-transaction",
    redirect: "/admin/va-transaction/index",
    component: BaseLayout,
    meta: {
      middleware: authMiddleware,
    },
    children: [
      {
        path: "/admin/va-transaction/index",
        component: ListTransactionVA,
        name: 'admin-va-transaction',
        meta: { requiresAuth: true },
      },
      {
        path: "/admin/va-transaction/:id",
        component: DetailTransaction,
        name: 'admin-va-transaction-detail',
        meta: { requiresAuth: true },
      },
    ],
  }, {
    path: "/admin/account",
    redirect: "/admin/account/index",
    component: ManagerMerchant,
    meta: {
      middleware: authMiddleware, // Truyền middleware vào meta
      // loggedIn: false, // Giá trị của biến loggedIn
    },
    children: [
      {
        path: "/admin/account/index",
        component: account,
        meta: { requiresAuth: true },
      },
      {
        path: "/admin/account/permission/:id?",
        component: PermissionUser,
        meta: { requiresAuth: true },
      },
      {
        path: "/admin/account/add",
        component: formAdmin,
        meta: { requiresAuth: true },
      },
      {
        path: "/admin/account/edit/:id",
        component: Register,
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: "/admin/role",
    redirect: "/admin/role/index",
    component: ManagerMerchant,
    meta: {
      middleware: authMiddleware, // Truyền middleware vào meta
      // loggedIn: false, // Giá trị của biến loggedIn
    },
    children: [
      {
        path: "/admin/role/index",
        component: role,
        meta: { requiresAuth: true },
      },
      {
        path: "/admin/role/action/:id?",
        component: Action,
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: "/admin/permission",
    redirect: "/admin/permission/index",
    component: ManagerMerchant,
    meta: {
      middleware: authMiddleware, // Truyền middleware vào meta
      // loggedIn: false, // Giá trị của biến loggedIn
    },
    children: [
      {
        path: "/admin/permission/index",
        component: permisison,
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: "/admin/report/transaction",
    redirect: "/admin/report/merchant",
    component: ManagerMerchant,
    meta: {
      middleware: authMiddleware, // Truyền middleware vào meta
    },
    children: [
      {
        path: "/admin/report/transaction",
        component: reportMerchant,
        meta: { requiresAuth: true },
      },
    ],
  },



  {
    path: "/auth",
    redirect: "/auth/login",
    component: Auth,
    children: [
      {
        path: "/auth/login",
        component: Login,
      },
      {
        path: "/auth/register",
        component: Register,
      },
    ],
  },
  {
    path: "/:catchAll(.*)",
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// Thêm middleware cho từng định tuyến
router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = to.meta.middleware;
    middleware(next);
  } else {
    next();
  }
});

export default router;
