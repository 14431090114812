// store.js
import { reactive, toRefs } from 'vue';

export const auth = () => {
  let userInfo = JSON.parse(localStorage.getItem('user_info') || "{}");
  if (typeof userInfo === 'string') {
    userInfo = JSON.parse(userInfo);
  }

  return userInfo;
}

const state = reactive({
  isAuthenticated: false,
  auth: auth()
});

const mutations = {
  login() {
    state.isAuthenticated = true;
    state.auth = auth();
  },
  logout() {
    state.isAuthenticated = false;
    state.auth = null;
  },
};

export default {
  ...toRefs(state),
  mutations,
};
